<template>
  <div>

    <div class="form" style="min-height: calc( 100vh - 100px ) " >
      <el-form label-width="100px"
               :hide-required-asterisk="true"
               ref="add_form"
               @submit.native.prevent
               :model="add_form"
               :rules="add_rules" >
        <div class="content">
          <el-form-item label="轮播图名称" prop="title">
            <el-input maxlength="30" v-model="add_form.title" placeholder="请输入轮播图名称"></el-input>
          </el-form-item>
          <el-form-item label="轮播图" prop="bannerPath">
            <el-upload
                ref="uploadProductBanner"
                :class="uploadDisabledProductBanner"
                list-type="picture-card"
                accept=".jpg,.png"
                :action="action"
                :file-list="fileListBanner"
                :headers="headers"
                :limit="1"
                :on-change="handleChangePicProductBanner"
                :on-success="handleAvatarSuccessPicProductBanner"
                :before-upload="beforeAvatarUploadPicProductBanner">
              <i slot="default" class="el-icon-plus"></i>
              <div class="el-upload__tip" slot="tip">只能上传jpg/jpeg/png/bmp文件</div>
              <div class="upload_image" slot="file" slot-scope="{file}">
                <el-image
                    v-if="file.url"
                    :src="file.url">
                </el-image>
                <el-image-viewer
                    v-if="showViewer"
                    :on-close="closeViewer"
                    :url-list="[file.url]" />

                <span class="el-upload-list__item-actions">
                <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file,showViewer= true)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                    class="el-upload-list__item-delete"
                    @click="handleRemoveProductBanner(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="是否外部链接" prop="isExternalUrl">
            <el-select v-model="add_form.isExternalUrl" clearable placeholder="请选择是否外部链接">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跳转链接地址" prop="jumpUrl">
            <el-input v-model="add_form.jumpUrl" clearable placeholder="请输入跳转链接地址"></el-input>
          </el-form-item>
          <el-form-item label="次序" prop="sequence">
            <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="add_form.sequence" clearable placeholder="请输入次序"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-switch
                v-model="add_form.status"
                active-value="1"
                inactive-value="0"
                active-text="展示"
                inactive-text="隐藏">
            </el-switch>
          </el-form-item>
        </div>
        <el-form-item>
          <div class="button">
            <el-button size="small" @click="addClose">取消</el-button>
            <el-button size="small" native-type="submit" type="primary" @click="onSubmitAdd(add_form)" >确定保存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
export default {
  name: "ActivityDeta",
  data() {
    return {
      add_form: {
        id: null,
        title: null, // 标题
        bannerPath: null, // banner Path
        isExternalUrl: null, // 是否外部链接
        jumpUrl: null, // 要跳转的url地址
        sequence: null, // 轮播图当前次序
        status: "1", // 展示状态
      },
      add_rules: {
        title: [
            { required: true, message: '请输入轮播图名称', trigger: 'blur' },
        ],
        bannerPath: [
            { required: true, message: '请输入上传轮播图', trigger: 'blur' },
        ],
        isExternalUrl: [
            { required: true, message: '请选择是否外部链接', trigger: 'blur' },
        ],
        jumpUrl: [
            { required: true, message: '请输入跳转链接地址', trigger: 'blur' },
        ],
        sequence: [
            { required: true, message: '请输入次序', trigger: 'blur' },
        ],
        status: [
            { required: true, message: '请选择状态', trigger: 'blur' },
        ],
      },
      // 上传
      action: process.env.VUE_APP_APIURL + "activityManager/banner/upload",
      headers: {
        authorization: this.$store.state.token
      },
      fileListBanner: [],
      uploadDisabledProductBanner: null,
      showViewer: false
    }
  },
  created() {

    if(this.$route.query.id) {
      this.getDeta(this.$route.query.id);
    }

  },
  methods: {
    getDeta(e) {
      this.$get("/activityManager/banner/banner",e,true)
        .then(res => {
          if(res) {
            console.log(res);
            this.add_form= res.data;

            let obj= {};
            obj["url"]= res.data.bannerUrl;
            this.fileListBanner= [obj];

            if(res.data.bannerUrl) {
              this.uploadDisabledProductBanner= "disabled";
            }

          }
        })
    },
    // 取消
    addClose() {
      this.$router.go(-1)
    },
    // 保存
    onSubmitAdd(formName) {
      this.$refs["add_form"].validate((valid) => {
        if(valid) {

          this.$post("activityManager/banner",formName)
            .then(res => {
              if(res) {
                console.log(res)

                this.$message.success(res.message);

                this.addClose()

              }
            })


        }
      })
    },
    // 上传
    // onchange
    handleChangePicProductBanner(file,fileList){
      console.log(fileList)
      if(fileList.length > 0) {
        this.uploadDisabledProductBanner= "disabled";
      }

    },
    // 上传成功
    handleAvatarSuccessPicProductBanner(res, file) {
      console.log(res)
      console.log(file)

      this.add_form.bannerPath= res.data.bannerPath;

      console.log(this.add_form);

    },
    // 上传前
    beforeAvatarUploadPicProductBanner(file) {
      let extension = file.name.split(".")[1];
      let extensionList = ["JPG","jpg","jpeg","JPEG","png","PNG","bmp","BMP"];

      if (extensionList.indexOf(extension) < 0) {
        this.$message.warning("只能上传jpg/jpeg/png/bmp文件");
        return false;
      }
    },
    // 图片查看器 - 打开
    handlePictureCardPreview() {

    },
    // 图片查看器 - 关闭
    closeViewer() {
      this.showViewer= false;
    },
    // 删除
    handleRemoveProductBanner(file) {

      let num = 0;

      this.$refs["uploadProductBanner"].uploadFiles.map(item => {
        if (item.uid == file.uid) {
          this.$refs["uploadProductBanner"].uploadFiles.splice(num, 1);
          this.uploadDisabledProductBanner= null;
          this.add_form.bannerPath= null;
        }
        num++
      })


    }
  }
}
</script>

<style lang="less" scoped>
  @import "../../style/Product/ProductDeta.less";
  .disabled{
    /deep/ .el-upload--picture-card{
      display: none!important;
    }
  }

  .el-form{
    min-height: calc( 100vh - 140px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
